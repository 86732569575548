import { required, maxLength } from "vuelidate/lib/validators";
import { especiales, onlyMayusText } from '@/_validations/validacionEspeciales';

export default () => {
    return {
        GpoSupplyId: { required },
        SupplyName: { required, onlyMayusText, maxLength: maxLength(50) }
    }
}

