<template>
<div>
  <loading-overlay :active="loadingGeneral" :is-full-page="true" loader="bars" />
  <CCard>
    <CCardHeader class="bg-dark text-white">
      <CRow>
        <CCol sm="10" class="d-flex mt-1">{{collapseTitle}}</CCol>
        <CCol sm="2">
          <CButton color="edit" size="sm" class="float-right" @click="tablaCollapse = !tablaCollapse" v-c-tooltip="{content: $t('label.dataTable')}" >
            {{ cambiaTexto }}
            <CIcon :name="cambiaIcono"/>
          </CButton>
        </CCol>
      </CRow>
    </CCardHeader>
    <CCardBody>
      <CRow>
        <CCol sm="12" lg="10">
          <CRow>
            <CCol sm="12"> <!--:is-valid="statusSelectGp" :invalid-feedback="$t('label.required')+$t('label.onlyText')"-->
                <CSelect
                    :label="$t('label.gpoSupply')"
                    addLabelClasses="text-right"                
                    :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'}"
                    v-model="$v.GpoSupplyId.$model"
                    :value.sync="GpoSupplyId"
                    :options="tpOptions"
                    :invalid-feedback="errorMessage($v.GpoSupplyId)"
                    :is-valid="hasError($v.GpoSupplyId)"
                />
            </CCol>
            <CCol sm="12"> 
              <CInput
                :label="$t('label.supplie')"
                addLabelClasses="required text-right"                
                :placeholder="$t('label.name')"
                :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'}"
                v-uppercase
                v-model.trim="$v.SupplyName.$model"
                :is-valid="hasError($v.SupplyName)"
                maxlength="100"
                :invalid-feedback="errorMessage($v.SupplyName)"
                
              />
            </CCol>
            <CCol sm="12" v-if="actualizar">
              <CSelect
                  :label="$t('label.status')"
                  addLabelClasses="required text-right"  
                  :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'}"
                  v-model="Status"
                  :value.sync="Status"
                  :options="selectOptions"
                  :is-valid="statusSelectColor"
                />
            </CCol>
          </CRow>
        </CCol>
        <CCol sm="12" lg="2" class="text-center">
          <CButton
            size="sm"
            color="add"
            class="mr-1"
            v-c-tooltip="{content: $t('label.add')}"
            @click="evaluaStatus"
            :disabled="isSubmit"
            v-if="editInsumo"
          >
            <CIcon name="checkAlt" />
          </CButton>
          <CButton
            size="sm"
            color="add"
            class="mr-1"
            v-c-tooltip="{content: $t('label.add')}"
            v-if="!editInsumo"
            :disabled="isSubmit"
            @click="evaluaStatus"
          >
            <CIcon name="checkAlt" />
          </CButton>
          <CButton color="wipe" class="mr-1" size="sm" v-c-tooltip="{content: $t('label.clearFields')}" @click="limpiarDatos" >
            <CIcon name="cil-brush-alt"/>
          </CButton>
        </CCol>
      </CRow>
      <CCollapse :show="tablaCollapse" class="m-3">
        <dataTableExtended
     class="align-center-row-datatable"
          :items="items"
          :fields="fields"
          :loading="loadingTable"
          column-filter
          :noItemsView="tableText.noItemsViewText"
          :table-filter="tableText.tableFilterText"
          :items-per-page-select="tableText.itemsPerPageText"
          :items-per-page="5"
          hover
          small
          sorter
          pagination
           
        >
          <template #loading>
            <loading/>
          </template>
          <template #Status="{item}">
            <td class="cell-center text-center">
              <CBadge :color="getBadge(item.Status)">
                {{ $t('label.'+item.Status) }}
              </CBadge>
            </td>
          </template>
          <template #Detalle="{item}">
          <td class="py-2 text-center">
            <CButton color="edit" class="mr-1" size="sm" v-c-tooltip="{content: $t('label.edit')+' '+$t('label.supplie')}" @click="editDatos(item)" >
              <CIcon name="pencil" />
            </CButton>
            <!--<CButton color="wipe" class="mr-1" size="sm" v-c-tooltip="{content: $t('label.delete')+' '+$t('label.supplie')}" @click="ConfirmarEliminado(item)" >
              <CIcon name="cil-trash" />
            </CButton>-->
          </td>
        </template>
        </dataTableExtended>
      </CCollapse>
    </CCardBody>
  </CCard>
</div>
</template>

<script>
import { DateFormater, alertPropertiesHelpers } from '@/_helpers/funciones';
import UpperCase  from '@/_validations/uppercase-directive';
import General from '@/_mixins/general';
import InsumoValidations from '@/_validations/planificacion-estiba/insumoValidations';

//Data
function data() {
  return {
    items: [],
    tpOptions: [],
    muestraTabla: false,
    loadingGeneral: false,
    loadingTable: false,
    editInsumo: false,
    tablaCollapse: false,
    actualizar: false,
    isSubmit: false,
    iconoCollapse: 'cil-arrow-circle-top',
    //
    Status: 1,
    GpoSupplyId: '',
    SupplyId: '',
    SupplyName: '',
  }
}

function fields(){
  return [
    { 
      key: 'Nro', 
      label: '#',
      _style: 'width:1%; text-align:center;', filter: false
    },
    { key: 'SupplyName', label:this.$t('label.Supplies'), _style: 'width:30%;' },
    { key: 'GpoSupplyName', label: this.$t('label.supplier'), _style: 'width:20%;' },
    { key: 'TransaLogin', label: this.$t('label.user'), _style: 'width:10%;'},
    { key: 'Fecha', label:this.$t('label.date'), _classes:'center-cell text-center', _style: 'width:10%;'},
    { key: 'Status', label:this.$t('label.status'), _classes:'cell-center text-center', _style: 'width:10%;' },
    { 
      key: 'Detalle',
      label: '',
      sorter: false,
      filter: false,
      _style: 'min-width:45px; width:2%;'
    }
  ];
}

function selectOptions(){
  return [
    { 
      value: 1, 
      label: this.$t('label.ACTIVO')
    },
    { 
      value: 0, 
      label: this.$t('label.INACTIVO')
    }
  ];
}

function statusSelectGp(){
    return this.GpoSupplyId !== "";
  }

function cambiaIcono() {
  return this.iconoCollapse = (this.tablaCollapse) ? 'cil-chevron-top-alt' : 'cil-chevron-bottom-alt';
}
function cambiaTexto() {
  return this.textoCollapse = (this.tablaCollapse) ? this.$t("label.close") : this.$t("label.toView");
}
function collapseTitle(){
  return this.actualizar ? this.$t('label.edit')+' '+this.$t('label.supplie')+': '+this.SupplyName : this.$t('label.nuevo')+' '+this.$t('label.supplie');
}
function cerrar() {
    this.limpiarDatos();
    this.$emit('child-refresh',true);
}

async function listarSupplies () {
  this.items = [];
  this.loadingTable = true;
  
  let listado = Array;
  await this.$http.get("Supply-list", { GpoSupplyId: '', filter: 'ALL' })
  .then(response => {
    listado = [...response.data.data];
    this.items = listado.map(listado => Object.assign({}, this.items, {
        Nro: listado.Nro,
        SupplyId: listado.SupplyId,
        GpoSupplyId: listado.GpoSupplyId, 
        GpoSupplyName: listado.GpoSupplyName, 
        SupplyName: listado.SupplyName, 
        FgActSupply: listado.FgActSupply, 
        TransaLogin: listado.TransaLogin ? listado.TransaLogin:'N/A',
        Fecha: listado.TransaRegDate ? DateFormater.formatOnlyDateWithSlash(listado.TransaRegDate) : 'N/A',
        Status: listado.Status,
        _classes: listado.Status === "INACTIVO" ? 'table-danger': ''
    }));
  }).catch( err => {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
    });
  })
  .finally(() => {
    this.loadingTable = false;
  });
}

async function listarTpSupplies () {
  this.tpOptions = [];
  this.loadingTable = true;
  
  let listado = Array;
  await this.$http.get("GpoSupply-list", { Filter: 'ACTIVO' })
  .then(response => {
    listado = [...response.data.data];
    this.tpOptions = listado.map(listado => Object.assign({}, this.tpOptions, {
      value: listado.GpoSupplyId,
      label: listado.GpoSupplyName,
    }));

    this.tpOptions.push({
      value: "",
      label: this.$t('label.select'),
    });
  }).catch( err => {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
    });
  })
  .finally(() => {
    this.loadingTable = false;
  });
}

function limpiarDatos() {
    this.isSubmit=false;
    this.SupplyName='';
    this.Status=1;
    this.GpoSupplyId='';
    this.SupplyId=true;
    this.actualizar = false;
    this.editInsumo = false;
    this.$nextTick(() => { this.$v.$reset() })
}

function editDatos(item) {
    this.SupplyName = item.SupplyName;
    this.Status = item.FgActSupply ? 1 : 0;
    this.GpoSupplyId = item.GpoSupplyId;
    this.SupplyId = item.SupplyId;
    this.actualizar = true;
    this.editInsumo = true;
    this.$v.$touch();
}

function evaluaStatus() {
  if (this.Status===0) {
    this.$swal.fire(
        alertPropertiesHelpers(this, {
          text: `${this.$t('label.changeStatusQuestion')+' '+this.SupplyName}`
        })
      ).then((result) => {
      if (result.isConfirmed) {
        this.guardar();
      }
    });  
  } else this.guardar();
}
  
function guardar () {
  try{
    this.isSubmit = true;
    this.loadingGeneral = true;
    this.$v.$touch();
    if (this.$v.$error) {
      this.loadingOverlay = false;
      this.isSubmit = false;
      throw this.$t('label.errorsPleaseCheck');
    }
    let SupplyJson    = [];
    let metodo      = '';
    let ruta        = '';
    let res         = [];

    if (this.actualizar) {
      SupplyJson = {
        SupplyId: this.SupplyId,
        GpoSupplyId: this.GpoSupplyId,
        SupplyName: this.SupplyName,
        Status: this.Status
      };
      metodo = 'PUT';
      ruta = "Supply-update";
    } else {
      SupplyJson = {
        GpoSupplyId: this.GpoSupplyId,
        SupplyName: this.SupplyName,
      };
      metodo = 'POST';
      ruta = "Supply-insert";
    }

    this.$http.ejecutar(metodo, ruta, SupplyJson, { root: 'SupplyJson' })
    .then(response => {
      res = [...response.data.data];
      this.$notify({
        group: 'container',
        title: '¡Exito!',
        text: res[0].Response,
        type: "success"
      });
      this.isSubmit = false;
      this.loadingGeneral= false;
      this.limpiarDatos();
      this.listarSupplies();
    }).catch((err) => {
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
      this.isSubmit = false;
      this.loadingGeneral= false;
    });
  }catch(e){
    this.isSubmit = false;
    this.loadingGeneral = false;
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: e,
      type: "error"
    });
  }
  
}

/*function ConfirmarEliminado(item) {
  this.$swal.fire(
      alertPropertiesHelpers(this, {
        text: `${this.$t('label.changeStatusQuestion')+' '+item.SupplyName}`
      })
    ).then((result) => {
    if (result.isConfirmed) {
      this.loadingGeneral = true;
      let res = [];
      let SupplyJson = [];

      SupplyJson = {
        SupplyId: item.SupplyId,
        GpoSupplyId: item.GpoSupplyId,
        SupplyName: item.SupplyName,
        Status: 0
      };

      this.$http.put("Supply-update", SupplyJson, { root: 'SupplyJson' })
      .then(response => {
        res = [...response.data.data];
        this.$notify({
          group: 'container',
          title: '¡Exito!',
          text: res[0].Response,
          type: "success"
        });
        this.limpiarDatos();
        this.listarSupplies();
        this.loadingGeneral= false;    
      }).catch((err) => {
        this.$notify({
          group: 'container',
          title: '¡Error!',
          text: err,
          type: "error"
        });
        this.loadingGeneral= false;
      });
    }
  });  
}*/

export default {
  name: 'add-insumo',
  mixins: [General],
  data,
  directives: UpperCase,
  validations: InsumoValidations,
  props: ['value'],
  methods: {
    cerrar,
    listarSupplies,
    listarTpSupplies,
    statusSelectGp,
    editDatos,
    limpiarDatos,
    evaluaStatus,
    guardar,
    //ConfirmarEliminado
  },
  computed: {
    collapseTitle,
    cambiaIcono,
    selectOptions,
    fields, 
    cambiaTexto
  },
  mounted () {
    if (this.value) {
        this.editDatos(this.value);
    }
    this.listarSupplies();
    this.listarTpSupplies();
  },
}
</script>
<style lang="scss" scope>
  .center-cell {
  text-align: center;
}
 
</style>